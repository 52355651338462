import { get, post, put, destroy } from "./_utils";

export const updateCollaborativeForm = (formId, collaborativeFormId, data) => {
  return put(
    `/forms/${formId}/collaborative_forms/${collaborativeFormId}.json`,
    data,
  );
};

export const destroyCollaborativeForm = (formId, collaborativeFormId) => {
  return destroy(
    `/forms/${formId}/collaborative_forms/${collaborativeFormId}.json`,
  );
};

export const getCollaborativeForms = (formId) => {
  return get(`/forms/${formId}/collaborative_forms.json`);
};

export const getCollaborativeForm = (formId, collaborativeFormId) => {
  return get(
    `/forms/${formId}/collaborative_forms/${collaborativeFormId}.json`,
  );
};

export const createCollaborativeForm = (formId, data = {}) => {
  return post(`/forms/${formId}/collaborative_forms.json`, data);
};
